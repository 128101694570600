<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />出行查询
		</div>

		<!-- 列表 -->
		<div style="padding-top: 60px;">
			<div class="listBox">
				<div class="itemBox" v-for="(item, index) in list" @click="topage(item.url)">
					<img :src="item.pic" />
					<div>{{item.name}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'
	export default {
		data() {
			return {
				list: [{
						pic: require('../../assets/images/chuxing2.png'),
						name: '高铁查询',
						url: '/gaotieList'
					},
					{
						pic: require('../../assets/images/chuxing1.png'),
						name: '实时公交',
						url: '/gongjiao'
					}
				]
			}
		},

		methods: {
			back(){
				router.back()
			},
			
			topage(url) {
				if (url == '') {
					return
				}
				router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped>
	.listBox {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.itemBox {
		width: 25%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 26px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 37px;
		white-space: nowrap;
	}
	
	.itemBox img {
		width: 88px;
		height: 88px;
		margin-bottom: 15px;
	}
</style>