import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'
import daohang from '../views/home/daohang.vue'
import jinzhan from '../views/home/jinzhan.vue'
import chuzhan from '../views/home/chuzhan.vue';
import chuxing from '../views/home/chuxing.vue';
import fuwu from '../views/home/fuwu.vue'
import shiwuzhaoling from '../views/home/shiwuzhaoling.vue'
import sousuo from '../views/home/sousuo.vue'
import nav from '../views/HomeView.vue'
import mapnav from '../views/map_navigation.vue'
import newlist from '../views/home/newList.vue'
import newinfo from '../views/home/newInfo.vue'
import jianyi from '../views/home/jianyi.vue'
import tousu from '../views/home/tousu.vue'
import success from '../views/home/success.vue'
import gongjiao from '../views/home/gongjiao.vue'
import gaotieList from '../views/home/gaotieList.vue'
import gongjiaoinfo from '../views/home/gongjiaoInfo.vue'
import teshulist from '../views/home/teshulist';

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: home
}, {
	path: '/daohang',
	name: 'daohang',
	component: daohang
}, {
	path: '/jinzhan',
	name: 'jinzhan',
	component: jinzhan
}, {
	path: '/chuzhan',
	name: 'chuzhan',
	component: chuzhan
}, {
	path: '/fuwu',
	name: 'fuwu',
	component: fuwu
}, {
	path: '/chuxing',
	name: 'chuxing',
	component: chuxing
}, {
	path: '/shiwuzhaoling',
	name: 'shiwuzhaoling',
	component: shiwuzhaoling
}, {
	path: '/sousuo',
	name: 'sousuo',
	component: sousuo
}, {
	path: '/nav',
	name: 'nav',
	component: nav
}, {
	path: '/newlist',
	name: 'newlist',
	component: newlist
}, {
	path: '/newinfo',
	name: 'newinfo',
	component: newinfo
}, {
	path: '/jianyi',
	name: 'jianyi',
	component: jianyi
}, {
	path: '/tousu',
	name: 'tousu',
	component: tousu
}, {
	path: '/success',
	name: 'success',
	component: success
}, {
	path: '/gongjiao',
	name: 'gongjiao',
	component: gongjiao
}, {
	path: '/gaotieList',
	name: 'gaotieList',
	component: gaotieList
}, {
	path: '/gongjiaoinfo',
	name: 'gongjiaoinfo',
	component: gongjiaoinfo
}, {
	path: '/mapnav',
	name: 'mapnav',
	component: mapnav
}, {
	path: '/teshulist',
	name: 'teshulist',
	component: teshulist
}]

const router = new VueRouter({
	routes
})

export default router