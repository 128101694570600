import {
	getapi,
	postapi
} from "../uitls/http.js"

export const wechatlogin = (data) => getapi("/index/wechatlogin", data);

export const bannerList = (data) => getapi("/index/bannerList", data);

export const messageList = (data) => getapi("/index/messageList", data);

export const messageInfo = (data) => getapi("/index/messageInfo", data);

export const convenientServices = (data) => getapi("/index/convenientServices", data);

export const complaintSuggestions = (data) => getapi("/index/complaintSuggestions", data);

export const commonConfig = (data) => getapi("/index/commonConfig", data);

// type: 1.进站 2.出站 3.卫生间 4.进站口 5.出站口 6.售票中心 7.地下一层 8.地下二层 9.出站闸机
export const xyz = (data) => getapi("/index/xyz", data);

export const xyzList = (data) => getapi("/index/xyzList", data);

export const xyzInfo = (data) => getapi("/index/xyzInfo", data);

export const trainList = (data) => getapi("/index/trainList", data);

export const busList = (data) => getapi("/index/busList", data);

export const busInfo = (data) => getapi("/index/busInfo", data);