<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />智慧北站
		</div>

		<!-- 搜索 -->
		<div style="padding-top: 30px;">
			<div class="sousuoBox">
				<div class="sousuoInput">
					<img src="../../assets/images/sousuo.png" />
					<input v-model="keywords" @input="getList" placeholder="请输入搜索地点或地址" />
				</div>
				<img v-if="keywords" @click="keywords = '';list = []" class="sousuoTest"
					src="../../assets/images/cha.png" />
			</div>

			<!-- 搜索列表 -->
			<div class="listBox" v-if="list.length > 0">
				<div class="listItem" :style="(index+1) == list.length ? 'border: 0px' : ''"
					v-for="(item, index) in list" @click="topage('/nav', item.id)">
					<div class="listInfo">
						<img src="../../assets/images/dingwei-5.png" />
						<div>
							<div class="listName">{{item.name}}</div>
							<div class="listCont">{{item.km}} 步行约{{item.expect_time}}分钟</div>
						</div>
					</div>
					<img src="../../assets/images/luxian.png" />
				</div>
			</div>
			<div v-if="list.length == 0" class="datanull">暂无数据</div>

			<!-- logo -->
			<img class="logo" src="../../assets/images/logo.png" />
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		xyzList,
		xyzInfo
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				list: [],
				keywords: '',
				type: ''
			}
		},

		mounted() {
			this.keywords = this.$route.query.name
			this.type = this.$route.query.type
			if (this.$route.query.type) {
				xyzList({
					page: 1,
					limit: 1000,
					keywords: '',
					type: this.type
				}).then((res) => {
					this.list = res.data.data
				})
			} else {
				xyzList({
					page: 1,
					limit: 1000,
					keywords: this.$route.query.name,
					type: ''
				}).then((res) => {
					this.list = res.data.data
				})
			}
		},

		methods: {

			getList() {
				if (this.keywords == '') {
					this.list = []
					return
				}
				xyzList({
					page: 1,
					limit: 1000,
					keywords: this.keywords,
					type: ''
				}).then((res) => {
					this.list = res.data.data
				})
			},

			back() {
				router.back()
			},

			topage(url, id) {
				xyzInfo({
					id: id
				}).then((res) => {
					let loulist = [res.data.data.floor_start, res.data.data.floor_end]
					let louceng = Array.from(new Set(loulist))
					for (var i = 1; i <= 9; i++) {
						if (res.data.data.floor_start < res.data.data.floor_end) {
							if (i > res.data.data.floor_start && i < res.data.data.floor_end) {
								louceng.push(i)
							}
						} else {
							if (i > res.data.data.floor_end && i < res.data.data.floor_start) {
								louceng.push(i)
							}
						}
					}
					
					var from = {
						floorNumber: res.data.data.floor_start,
						point: {
							x: res.data.data.x_start,
							y: res.data.data.y_start,
							z: res.data.data.z_start
						}
					}
					var to = {
						floorNumber: res.data.data.floor_end,
						point: {
							x: res.data.data.x_end,
							y: res.data.data.y_end,
							z: res.data.data.z_end
						}
					}
					localStorage.setItem('id', id);
					localStorage.setItem('from', JSON.stringify(from));
					localStorage.setItem('to', JSON.stringify(to));
					localStorage.setItem('louceng', JSON.stringify(louceng.sort()));
					localStorage.setItem('voice', res.data.data.voice)
					router.push({
						path: url
					})
				})
			}
		}
	}
</script>

<style scoped>
	.sousuoBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 656px;
		height: 78px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 48px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 51px 24px;
		padding: 0 23px;
	}

	.sousuoInput {
		display: flex;
		align-items: center;
	}

	.sousuoInput img {
		width: 31px;
		height: 31px;
		margin-right: 24px;
	}

	.sousuoInput input {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		width: 500px;
		border: 0px;
		background-color: #F6F9FF;
	}

	.sousuoTest {
		width: 26px;
		height: 26px;
	}

	.listBox {
		width: 656px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 20px;
		border: 1px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 51px 24px;
		padding: 0 23px;
	}

	.listItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 35px 0;
		border-bottom: 1px solid #CACACA;
	}

	.listItem img {
		width: 63px;
		height: 63px;
	}

	.listInfo {
		display: flex;
		align-items: center;
	}

	.listInfo img {
		width: 36px;
		height: 45px;
		margin-right: 22px;
	}

	.listName {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		margin-bottom: 6px;
	}

	.listCont {
		font-size: 27px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #939FA9;
		line-height: 38px;
	}
</style>