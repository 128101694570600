<template>
	<div ref="container" style="height: 100%;">
		<div class="start">
			<div class="start-item" @click="topage('/')">
				<img style="width: 25px;height: 25px;margin-right: 10px;" src="../assets/images/icon1-y.png" />回到首页
			</div>
			<div style="border-left: 1px solid #999;height: 20px;"></div>
			<div v-if="id != ''" class="start-item" @click="start_nav">
				<img style="width: 25px;height: 25px;margin-right: 10px;"
					src="../assets/images/icon2-y.png" />{{startnav_text}}
			</div>
		</div>

		<div>
			<audio ref="audioRef" hidden="true" :src="audioSrc"></audio>
		</div>

		<!-- 上行提示 -->
		<div class="upsty" v-if="upshow">
			<div>{{upTest}}</div>
			<div v-for="(item, index) in upImg">
				<img :src="item" />
			</div>
		</div>

		<!-- 热点 -->
		<div class="upsty2" v-if="rdshow">
			<div v-for="(item, index) in rdimg">
				<img :src="item" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		onBeforeUnmount
	} from 'vue';
	import {
		Map,
		SpriteMarkerNode,
		thr
	} from 'ooomap';
	import {
		SimulateNavi
	} from 'ooomap-simulate-navi';

	import router from '@/router'

	import {
		xyz
	} from '../../api/index.js'

	var upshow = ref(false)
	var upImg = ref([])
	var upTest = ref('')

	var rdshow = ref([false])
	var rdimg = ref([])

	const container = ref(null)
	var map
	var floorsComp
	var compassComp

	// 路线规划
	var pickCount = 0;
	var building;
	var from, to, simulator, routeResult;

	var startMarker, endMarker;

	window.glo = window.glo || {}

	const audioRef = ref(null);
	const audioSrc = localStorage.getItem('voice') ? localStorage.getItem('voice') : ''

	var id = localStorage.getItem('id') ? localStorage.getItem('id') : ''

	onMounted(() => {
		// 设置路线
		// xyz({
		// 	name: '公交站→候车厅',
		// 	x_start: -174.49938252109007,
		// 	y_start: -655.1548388621849,
		// 	z_start: 642.022587816293,
		// 	x_end: 6.234195559560817,
		// 	y_end: -22.86756727654938,
		// 	z_end: 911.2729832282583,
		// 	floor_start: 3,
		// 	floor_end: 4,
		// 	type: 1,
		// 	km: '',
		// 	expect_time: ''
		// }).then(res=>{
		// 	console.log(res.data)
		// })

		// 加载地图模型
		map = new Map({
			container: document.body,
			appID: '752e1a4fb13114b9ffb3d801ac73cae5',
			verifyUrl: 'https://www.ooomap.com/ooomap-verify/check/af19109faa1fca6ee94d35105fd3504e',
			backgroundColor: '#cbebee',
			backgroundAlpha: 1,
			viewFloors: JSON.parse(localStorage.getItem('louceng')) ? JSON.parse(localStorage.getItem(
				'louceng')) : [1, 2, 3, 4, 5],
			focusFloors: JSON.parse(localStorage.getItem('louceng')) ? JSON.parse(localStorage.getItem(
				'louceng')) : [1, 2, 3, 4, 5],
			frameRate: 30,
			floorSpace: 700, // 楼层间距, 默认为 68 米
			enableFloorAlpha: true, // false 取消非聚焦楼层的半透明显示
			eventsOnContainer: false, // 事件直接绑定在 canvas 上
			zoomLevel: 15
		})

		map.on('sceneLoaded', scene => {
			map.view.backgroundColor = '#cbebee';
			map.view.backgroundAlpha = 1;
			map.brightness = 1
			map.view.viewFloors = JSON.parse(localStorage.getItem('louceng')) ? JSON.parse(localStorage
				.getItem('louceng')) : [1, 2, 3, 4, 5]
			map.view.focusFloors = JSON.parse(localStorage.getItem('louceng')) ? JSON.parse(localStorage
				.getItem('louceng')) : [1, 2, 3, 4, 5]
		})

		// 常用地图事件代码提示
		// 点击拾取地图对象和坐标
		map.on('picked', res => {
			// res.node 点击到的元素实例
			// console.log(res.node, '拾取地图对象和坐标')
			console.log(res.intersect.point, '拾取地图对象和坐标')
			// console.log(res.node.position.clone(), '拾取地图对象和坐标')
		})
		// 场景加载完成事件
		// map.on('sceneLoaded', scene => {})
		// 楼层加载完成事件
		// map.on('buildingLoaded', building => {
		// 	building.getFloorByFloorNumber(0).then(floor => {})
		// })

		// 标注对象创建完成事件，每一个标注对象创建完成，都会触发次事件
		map.on('markerCreated', marker => {})

		// 加入楼层组件
		floorsComp = new Comp_floors({
			target: document.body,
			props: {
				hasOutdoor: true,
				style: 'right: 10px;bottom: 80px;',
			}
		})
		floorsComp.hasOutdoor = false
		floorsComp.bind(map);

		//
		// 加入指南针组件
		//
		compassComp = new Comp_compass({
			target: document.body,
		})
		compassComp.bind(map);
		window.addEventListener('resize', () => {
			map.view.resize();
		})

		// 创建POI标记的方法
		function createMarker(url) {
			var marker = new SpriteMarkerNode(map, {
				type: 'poi',
				autoHide: false, // 在遮挡时, 不会自动避让
				priority: 10,
				renderOrder: 3,
				content: {
					image: {
						url: url,
						width: 0,
						height: 0
					}
				}
			})
			// 先将标注隐藏, 在点击起/终点时再显示出来
			marker.show = false
			map.omScene.markerNode.add(marker)
			return marker;
		}

		// // 路线规划起始点(需要设置点所在的楼层floorNumber)
		from = JSON.parse(localStorage.getItem('from'))
		// from = {
		// 	floorNumber: 3,
		// 	point: {
		// 		x: -196.25679653182732,
		// 		y: -664.754702060724,
		// 		z: 844.3333523959636
		// 	}
		// }
		// // 路线规划终点(需要设置点所在的楼层floorNumber)
		to = JSON.parse(localStorage.getItem('to'))
		// to = {
		// 	floorNumber: 4,
		// 	point: {
		// 		x: 44.74355267047852,
		// 		y: 711.4575685394663,
		// 		z: 1262.5406793372058
		// 	}
		// }

		// window.glo = window.glo || {}

		var tujing = {
			floorNumber: 3,
			point: [96.298, 719.747]
		}

		map.on('buildingLoaded', b => {
			building = b

			startMarker = createMarker('https://www.ooomap.com/assets/map_images/start2.png');
			endMarker = createMarker('https://www.ooomap.com/assets/map_images/end2.png');

			// startMarker = createMarker(' ');
			// endMarker = createMarker(' ');

			simulator = new SimulateNavi(map, {
				building, // 传入所在的建筑
				startMarker, // 在 ooomap-init 里创建的startMarker
				endMarker // 在 ooomap-init 里创建的endMarker
			})

			// 当模拟导航开始时
			simulator.on('start', description => {
				console.log('>>> 导航描述数据:', description)
			})

			// 当模拟导航结束时
			simulator.on('complete', () => {
				console.log('>>> 模拟导航结束')
			})

			// 当模拟导航结束时, (导航完成也会触发结束事件)
			simulator.on('stop', () => {
				console.log('开启模拟导航')
			})

			if (localStorage.getItem('from')) {
				if ((from.floorNumber <= 2 && to.floorNumber == 4) || (from.floorNumber == 4 &&
						to.floorNumber <= 2)) {
					setTimeout(function() {
						// simulator.findPath({
						// 	building,
						// 	from,
						// 	to,
						// 	points: [from, tujing, to]
						// }).then(res => {
						// 	console.log('>>> routeResult: ', res)
						// 	routeResult = res
						// 	glo.routeResult = res
						// })
						building.findPath(from, tujing, to).then(res => {
							console.log('routeResult: ', res)
							routeResult = [res[0][0], res[0][1], res[1][1], res[1][2]]
							glo.routeResult = [res[0][0], res[0][1], res[1][1], res[1][2]]
						})
					}, 3000)
				} else {
					setTimeout(function() {
						simulator.findPath({
							building,
							from,
							to,
							points: [from, to]
						}).then(res => {
							console.log('>>> routeResult: ', res)
							routeResult = res
							glo.routeResult = res
						})
						// building.findPath(from, to).then(res => {
						// 	console.log('routeResult: ',res)
						// 	routeResult = res
						// 	glo.routeResult = res
						// })
					}, 3000)
				}
			}

			// if (localStorage.getItem('from')) {
			// 	setTimeout(function() {
			// 		building.findPath(from, to).then(res => {
			// 			console.log('>>> routeResult: ', res)
			// 			routeResult = res
			// 			glo.routeResult = res
			// 		})
			// 	}, 3000)
			// }
		})

		// 模型加载完成事件
		map.on('modelCreated', model => {
			switch (model.name) {
				case 'car': // 得到卡车模型对象
					glo.car = model
					break;
				case 'renwu': // 得到人物模型对象
					glo.woman = model
					break;
			}
		})

		map.on("update", start_dh)
		// map.on("update", res => {
		// 	if (!startnav) {
		// 		return
		// 	}
		// 	if (!glo.routeResult) {
		// 		return
		// 	}
		// 	if (glo.routeResult[routeResult_index].type) {
		// 		map.view.zoomLevel = 15
		// 	} else {
		// 		map.view.zoomLevel = 17
		// 	}
		// })

		const intervalId = setInterval(() => {
			// daojishi()
			if (!startnav) {
				return
			}
			// 如果卡车的模型还没有加载进来, 或路线规划没有完成时
			if (!glo.woman || !glo.routeResult) {
				return
			}
			time++
		}, 34);
	})

	onBeforeUnmount(() => {
		if (floorsComp.floorMaxHeight != 0) {
			map.dispose();
			localStorage.removeItem('id')
			localStorage.removeItem('from')
			localStorage.removeItem('to')
			localStorage.removeItem('louceng')
			localStorage.removeItem('voice')
			floorsComp.floorMaxHeight = 0
			floorsComp.has3DButton = false
			floorsComp.hasLayerButton = false
			floorsComp.hasOutdoor = false
			compassComp.bg = ''
			compassComp.pointer = ''
		}
	})

	// 设一个累加的长度
	var car_len = 0

	var time = 0
	// 移动的速度
	var speed = 100

	var startnav = false
	let startnav_text = ref('开始导航')

	var routeResult_index = 0

	function daojishi() {

	}

	function start_dh(delta) {
		if (!startnav) {
			return
		}
		// 如果卡车的模型还没有加载进来, 或路线规划没有完成时
		if (!glo.woman || !glo.routeResult) {
			return
		}

		var lineNode = ''

		if (glo.routeResult.length > 1) {
			lineNode = glo.routeResult[routeResult_index].lines[0]
		} else {
			lineNode = glo.routeResult.lines[0]
		}

		// 距离累加
		car_len += Number(delta * 100)

		console.log(time)

		if (localStorage.getItem('id') == 27) {
			if (Number(time) >= 0 && Number(time) <= 140) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/4-1.png']
			} else if (Number(time) >= 160 && Number(time) <= 245) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/6-1.png']
			} else if (Number(time) >= 245 && Number(time) <= 435) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 435 && Number(time) <= 520) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 560 && Number(time) <= 710) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou.jpg']
			} else {
				rdshow.value = false
				rdimg.value = []
			}
		}

		if (localStorage.getItem('id') == 18) {
			if (Number(time) >= 90 && Number(time) <= 220) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/2cengdixia.jpg']
			} else if (Number(time) >= 220 && Number(time) <= 300) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang.jpg']
			} else if (Number(time) >= 300 && Number(time) <= 350) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang2.jpg']
			} else if (Number(time) >= 350 && Number(time) <= 440) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang3.jpg']
			} else if (Number(time) >= 475 && Number(time) <= 510) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhanchurukou.jpg']
			} else if (Number(time) >= 540 && Number(time) <= 670) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou.jpg']
			} else {
				rdshow.value = false
				rdimg.value = []
			}
		}

		if (localStorage.getItem('id') == 14) {
			if (Number(time) >= 1 && Number(time) <= 20) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan1.jpg']
			} else if (Number(time) >= 20 && Number(time) <= 110) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan2.jpg']
			} else if (Number(time) >= 110 && Number(time) <= 380) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 380 && Number(time) <= 475) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 520 && Number(time) <= 655) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou.jpg']
			} else {
				rdshow.value = false
				rdimg.value = []
			}
		}

		if (localStorage.getItem('id') == 28) {
			if (Number(time) >= 0 && Number(time) <= 95) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chuzhankou.jpg']
			} else if (Number(time) >= 150 && Number(time) <= 245) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang3.jpg']
			} else if (Number(time) >= 245 && Number(time) <= 275) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang2.jpg']
			} else if (Number(time) >= 275 && Number(time) <= 370) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang.jpg']
			} else if (Number(time) >= 370 && Number(time) <= 590) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/2cengdixia.jpg']
			} else {
				rdshow.value = false
				rdimg.value = []
			}
		}

		if (localStorage.getItem('id') == 19) {
			if (Number(time) >= 0 && Number(time) <= 95) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chuzhankou.jpg']
			} else if (Number(time) >= 95 && Number(time) <= 140) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhanchurukou.jpg']
			} else if (Number(time) >= 140 && Number(time) <= 175) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 175 && Number(time) <= 600) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 600 && Number(time) <= 665) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan2.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 17) {
			if (Number(time) >= 0 && Number(time) <= 105) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chuzhankou.jpg']
			} else if (Number(time) >= 105 && Number(time) <= 200) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chuzuche.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 20) {
			if (Number(time) >= 0 && Number(time) <= 100) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chuzhankou.jpg']
			} else if (Number(time) >= 100 && Number(time) <= 160) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/cesuo1.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 16) {
			if (Number(time) >= 0 && Number(time) <= 40) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan1.jpg']
			} else if (Number(time) >= 40 && Number(time) <= 80) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan2.jpg']
			} else if (Number(time) >= 80 && Number(time) <= 430) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 430 && Number(time) <= 500) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 500 && Number(time) <= 555) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chuzhankou2.jpg']
			} else if (Number(time) >= 555 && Number(time) <= 592) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/cesuo1.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 29) {
			if (Number(time) >= 0 && Number(time) <= 120) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/4-1.png']
			} else if (Number(time) >= 160 && Number(time) <= 250) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/6-1.png']
			} else if (Number(time) >= 250 && Number(time) <= 475) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 475 && Number(time) <= 540) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 560 && Number(time) <= 600) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/shoupiao.png']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 21) {
			if (Number(time) >= 0 && Number(time) <= 30) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan1.jpg']
			} else if (Number(time) >= 30 && Number(time) <= 65) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan2.jpg']
			} else if (Number(time) >= 65 && Number(time) <= 385) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 385 && Number(time) <= 495) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 513 && Number(time) <= 550) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/shoupiao.png']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 31) {
			if (Number(time) >= 0 && Number(time) <= 150) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/4-1.png']
			} else if (Number(time) >= 185 && Number(time) <= 270) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/6-1.png']
			} else if (Number(time) >= 270 && Number(time) <= 590) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 590 && Number(time) <= 625) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan2.jpg']
			} else if (Number(time) >= 625 && Number(time) <= 670) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan1.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 26) {
			if (Number(time) >= 0 && Number(time) <= 144) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/4-1.png']
			} else if (Number(time) >= 175 && Number(time) <= 265) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/6-1.png']
			} else if (Number(time) >= 265 && Number(time) <= 490) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 490 && Number(time) <= 535) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 565 && Number(time) <= 700) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (localStorage.getItem('id') == 30) {
			if (Number(time) >= 0 && Number(time) <= 123) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/d1-2.jpg']
			} else if (Number(time) >= 123 && Number(time) <= 204) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/d1-2.jpg']
			} else if (Number(time) >= 204 && Number(time) <= 290) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/tl2.jpg']
			} else if (Number(time) >= 290 && Number(time) <= 325) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang2.jpg']
			} else if (Number(time) >= 325 && Number(time) <= 432) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/chengshitonglang3.jpg']
			} else if (Number(time) >= 450 && Number(time) <= 475) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhanchurukou.jpg']
			} else if (Number(time) >= 475 && Number(time) <= 520) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/jinzhankou2.jpg']
			} else if (Number(time) >= 520 && Number(time) <= 890) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/guangchang.jpg']
			} else if (Number(time) >= 890 && Number(time) <= 935) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan2.jpg']
			} else if (Number(time) >= 935 && Number(time) <= 970) {
				rdshow.value = true
				rdimg.value = ['https://wfbz.boyaokj.cn/bz/gongjiaozhan1.jpg']
			} else {
				rdshow.value = false
				rdimg.value = ['']
			}
		}

		if (car_len > lineNode.length) {
			if (glo.routeResult.length > 1) {
				if ((Number(routeResult_index + 2)) == glo.routeResult.length) {
					car_len = lineNode.length
					startnav_text.value = '重新导航'
					startnav = false
					// car_len = 0
					// window.glo = window.glo || {}
					// routeResult_index = 0
				} else {
					// routeResult_index = routeResult_index + 1
					// if (glo.routeResult[routeResult_index].floorNumber != glo.routeResult[routeResult_index-1].floorNumber) {
					// 	map.view.zoomLevel = 15
					// 	setTimeout(function(){
					// 		routeResult_index = routeResult_index + 1
					// 	}, 2000)
					// }else{
					// 	car_len = 0
					// 	map.view.zoomLevel = 17
					// }
					map.view.zoomLevel = 15
					startnav = false
					var north = new thr.Vector3(0, 1, 0)
					if (glo.routeResult[routeResult_index].floorNumber == 1 && glo.routeResult[routeResult_index + 1]
						.floorNumber == 3) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/1-1.jpg',
							'https://wfbz.boyaokj.cn/bz/2-2.jpg'
						]
						upTest.value = '您正从地下二层停车场前往出站广场层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 3 && glo.routeResult[routeResult_index +
							1].floorNumber == 1) {
						console.log(glo.routeResult[routeResult_index].floorNumber)
						upshow.value = true
						upImg.value = [
							'https://wfbz.boyaokj.cn/bz/1-2.jpg'
						]
						upTest.value = '您正从出站广场层前往地下二层停车场...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 3 && glo.routeResult[routeResult_index +
							1].floorNumber == 4) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/3-1.jpg']
						upTest.value = '您正从出站广场层前往进站层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 2 && glo.routeResult[routeResult_index +
							1]
						.floorNumber == 3) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/4-1.png',
							'https://wfbz.boyaokj.cn/bz/5-1.png',
							'https://wfbz.boyaokj.cn/bz/6-1.png'
						]
						upTest.value = '您正从地下一层停车场层前往出站广场层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 3 && glo.routeResult[routeResult_index +
							1]
						.floorNumber == 2) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/6-2.png',
							'https://wfbz.boyaokj.cn/bz/5-2.png',
							'https://wfbz.boyaokj.cn/bz/4-2.png'
						]
						upTest.value = '您正从出站广场层前往地下一层停车场层...'
					}
					setTimeout(function() {
						car_len = 0
						routeResult_index = routeResult_index + 1
						map.view.zoomLevel = 16
						startnav = true
						upshow.value = false
					}, 10000)
				}
			} else {
				car_len = lineNode.length
				startnav_text.value = '重新导航'
				startnav = false
				car_len = 0
				time = 0
				window.glo = window.glo || {}
			}
		}

		// 得到线上某一个给定长度的坐标以及方向
		// res = {point, direction}
		var res = lineNode.getPointByLength(car_len)

		glo.woman.entity.lookAt(res.point.clone().add(res.direction))
		// glo.woman.entity.rotateX(-Math.PI / 2)
		// glo.woman.entity.rotateZ(Math.PI / 2)

		// 将车移动到这个位置上
		if (localStorage.getItem('from')) {
			if (routeResult_index == 0) {
				res.point.z = JSON.parse(localStorage.getItem('from')).point.z
			} else if (glo.routeResult[routeResult_index].floorNumber == JSON.parse(localStorage.getItem('from'))
				.floorNumber) {
				res.point.z = JSON.parse(localStorage.getItem('from')).point.z
			} else if (glo.routeResult[routeResult_index].floorNumber == JSON.parse(localStorage.getItem('to'))
				.floorNumber) {
				res.point.z = JSON.parse(localStorage.getItem('to')).point.z
			} else {
				res.point.z = 1429.802609938351
			}
		} else {
			res.point.z = 0
		}

		glo.woman.position.copy(res.point)

		// 跟随位置
		map.view.viewCenter = glo.woman.position

		var angle = getAngleWithDirection(res.direction)
		// 将此设置为地图的角度
		map.view.viewAngle = angle
	}

	// 正北方向的向量
	var north = new thr.Vector3(0, 1, 0)

	function getAngleWithDirection(direction) {
		var rad = Math.acos(north.dot(direction))
		var angle = rad * 180 / Math.PI
		return angle
	}


	function start_nav() {
		if (startnav_text.value == '重新导航') {
			routeResult_index = 0
			car_len = 0
			time = 0
			window.glo = window.glo || {}
		}
		startnav = !startnav
		if (localStorage.getItem('voice') != 'null' && localStorage.getItem('voice')) {
			audioRef.value.play()
		}
		map.view.zoomLevel = 17
		if (startnav) {
			startnav_text.value = '暂停导航'
		} else {
			startnav_text.value = '继续导航'
		}
	}

	function topage(url) {
		map.dispose();
		localStorage.removeItem('id')
		localStorage.removeItem('from')
		localStorage.removeItem('to')
		localStorage.removeItem('louceng')
		localStorage.removeItem('voice')
		floorsComp.floorMaxHeight = 0
		floorsComp.has3DButton = false
		floorsComp.hasLayerButton = false
		floorsComp.hasOutdoor = false
		compassComp.bg = ''
		compassComp.pointer = ''
		router.go(-1)
	}
</script>

<style scoped>
	html,
	body {
		height: 100%;
		margin: 0;
		font-size: 20px;
	}

	#app {
		height: 0px;
		margin: 0;
	}

	.start {
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 95%;
		color: #000;
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 6rpx;
		background-color: #fff;
		box-shadow: 0 8px 12px 0 hsl(0deg 0% 0%);
		border-radius: 5rpx;
		padding: 20px;
		height: 50rpx;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}

	.start-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
	}

	.upsty {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 12000;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	.upsty img {
		margin-top: 10px;
		width: 500px;
	}

	.upsty2 {
		position: fixed;
		top: 20px;
		right: 20px;
		z-index: 12000;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	.upsty2 img {
		margin-top: 10px;
		width: 500px;
	}
</style>