<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />
			<span v-if="type == 1">失物招领</span>
			<span v-if="type == 2">物品借用</span>
			<span v-if="type == 3">陪伴服务</span>
			<span v-if="type == 4">盲人引导</span>
			<span v-if="type == 5">轮椅服务</span>
			<span v-if="type == 6">行李托运</span>
		</div>

		<!-- 内容 -->
		<div style="padding-top: 60px;">
			<div class="textareaBox">
				<div class="textareaTitle">情况描述（选填）</div>
				<textarea v-model="content" placeholder="请简单描述您的情况及其他事项"></textarea>
			</div>

			<div class="fromBox">
				<div class="fromItem">
					<div class="fromName">姓名</div>
					<input v-model="name" placeholder="请输入您的姓名" />
				</div>
				<div class="fromItem">
					<div class="fromName">联系电话</div>
					<input v-model="mobile" placeholder="请输入联系电话" />
				</div>
				<div class="fromItem">
					<div class="fromName">车次信息</div>
					<input v-model="train_info" placeholder="请输入车次信息" />
				</div>
				<div class="fromItem" v-if="type == 3">
					<div class="fromName">进/出站</div>
					<div class="fromcheck">
						<div class="fromcheckitem" @click="dx = 1">
							<img :src="dx == 1 ? dx_y : dx_n" />
							出站
						</div>
						<div class="fromcheckitem" @click="dx = 2">
							<img :src="dx == 2 ? dx_y : dx_n" />
							进站
						</div>
					</div>
				</div>
				<div class="fromPic">
					<div class="fromPicTitle">
						车票照片<span>{{images.length}}/9</span>
					</div>
					<div class="fromPicList">
						<div v-for="(item, index) in images" style="position: relative;">
							<img :src="item" />
							<img class="detimg" @click.stop="detImg(index)" src="../../assets/images/det.png" />
						</div>
						<el-upload v-if="images.length < 9" action="/api/file/upload"
							:before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :limit="9"
							:show-file-list="false">
							<img src="../../assets/images/upimg.png" />
						</el-upload>
					</div>
				</div>
			</div>

			<div class="submit" @click="submit">提交信息</div>
		</div>

	</div>
</template>

<script>
	import router from '@/router'
	import {
		Toast
	} from "vant";

	import {
		convenientServices
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				type: '',
				content: '',
				name: '',
				mobile: '',
				train_info: '',
				images: [],

				dx: null,
				dx_n: require('../../assets/images/dx_n.png'),
				dx_y: require('../../assets/images/dx_y.png')
			}
		},

		mounted() {
			// 1失物招领 2物品借用 3陪伴服务 4盲人引导 5轮椅服务 6行李托运
			this.type = this.$route.query.type
		},

		methods: {
			back() {
				router.back()
			},

			beforeAvatarUpload(e) {},

			handleAvatarSuccess(e) {
				if (e.code == 200) {
					this.images.push(e.data.url)
				} else {
					this.$message.error('上传失败');
				}
			},

			detImg(index) {
				this.images.splice(index, 1)
			},

			submit() {
				if (this.content == '') {
					this.$message.error('请输入情况描述')
					return
				}
				if (this.name == '') {
					this.$message.error('请输入您的姓名')
					return
				}
				if (this.mobile.length != 11) {
					this.$message.error('请输入联系电话')
					return
				}
				if (this.train_info == '') {
					this.$message.error('请输入车次信息')
					return
				}
				if (this.type == 3 && this.dx == null) {
					this.$message.error('请选择进站还是出站')
					return
				}
				if (this.images.length == 0) {
					this.$message.error('请上传车票照片')
					return
				}

				convenientServices({
					// user_id: '',
					type: this.type,
					content: this.content,
					name: this.name,
					mobile: this.mobile,
					train_info: this.train_info,
					images: this.images.join('|')
				}).then((res) => {
					if (res.data.code == 200) {
						this.$message.success('提交成功！')
						router.back()
					} else {
						this.$message.error('提交失败：' + res.data.message)
					}
				})
			},
		}
	}
</script>

<style scoped>
	.textareaBox {
		width: 702px;
		height: 425px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 19px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 20px 24px;
	}

	.textareaBox textarea {
		width: 650px;
		height: 300px;
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		line-height: 40px;
		border: 0px;
		margin: 21px 24px;
		background-color: #F6F9FF;
	}

	.textareaTitle {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		padding: 16px 0;
		margin: 0 24px;
		border-bottom: 1px dashed #D4D4D4;
	}

	.fromBox {
		width: 702px;
		/* height: 633px; */
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 19px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 20px 24px;
	}

	.fromItem {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		display: flex;
		align-items: center;
		padding: 32px 0;
		margin: 0 24px;
		border-bottom: 1px dashed #D4D4D4;
	}

	.fromName {
		width: 120px;
		margin-right: 38px;
	}

	.fromItem input {
		border: 0px;
		background-color: #F6F9FF;
	}

	.fromPic {
		padding: 32px 0;
		margin: 0 24px;
	}

	.fromPicTitle {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		margin-bottom: 28px;
	}

	.fromPicTitle span {
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9B9F9F;
		line-height: 40px;
		margin-left: 23px;
	}

	.fromPicList {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.fromPicList img {
		width: 148px;
		height: 148px;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.detimg {
		position: absolute;
		top: 0;
		right: 0;
		width: 30px !important;
		height: 30px !important;
	}

	.submit {
		width: 680px;
		height: 76px;
		background: linear-gradient(90deg, #478CF3 0%, #8ABAF4 100%);
		border-radius: 39px;
		margin: 45px auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 40px;
	}

	.fromcheck {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.fromcheckitem {
		width: 200px;
		display: flex;
		align-items: center;
	}

	.fromcheckitem img {
		width: 30px;
		height: 30px;
		margin-right: 5px;
	}
</style>