<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />建议
		</div>

		<!-- 内容 -->
		<div style="padding-top: 60px;">
			<div class="textareaBox">
				<div class="textareaTitle">问题描述</div>
				<textarea v-model="content" placeholder="请简单描述您的问题或建议"></textarea>
			</div>

			<div class="fromPic">
				<div class="fromPicTitle">
					上传凭证<span>{{images.length}}/9</span>
				</div>
				<div class="fromPicList">
					<div v-for="(item, index) in images" style="position: relative;">
						<img :src="item" />
						<img class="detimg" @click.stop="detImg(index)" src="../../assets/images/det.png" />
					</div>
					<el-upload v-if="images.length < 9" action="/api/file/upload" :before-upload="beforeAvatarUpload"
						:on-success="handleAvatarSuccess" :limit="9" :show-file-list="false">
						<img src="../../assets/images/upimg.png" />
					</el-upload>
				</div>
			</div>

			<div class="submit" @click="submit">提交信息</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		Toast
	} from "vant";

	import {
		complaintSuggestions
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				content: '',
				images: []
			}
		},

		methods: {
			back() {
				router.back()
			},

			beforeAvatarUpload(e) {},

			handleAvatarSuccess(e) {
				if (e.code == 200) {
					this.images.push(e.data.url)
				} else {
					this.$message.error('上传失败');
				}
			},

			detImg(index) {
				this.images.splice(index, 1)
			},

			submit() {
				if (this.content == '') {
					this.$message.error('请输入情况描述')
					return
				}

				complaintSuggestions({
					// user_id: '',.
					type: 1,
					content: this.content,
					images: this.images.join('|'),
				}).then((res) => {
					if (res.data.code == 200) {
						router.replace({
							path: '/success'
						})
					} else {
						this.$message.error('提交失败：' + res.data.message)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.textareaBox {
		width: 702px;
		height: 425px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 19px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 20px 24px;
	}

	.textareaBox textarea {
		width: 650px;
		height: 300px;
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9B9F9F;
		line-height: 40px;
		border: 0px;
		margin: 21px 24px;
		background-color: #F6F9FF;
	}

	.textareaTitle {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		padding: 16px 0;
		margin: 0 24px;
	}

	.fromPic {
		padding: 32px 23px;
		margin: 0 24px;
		background: rgba(255, 255, 255, 0.78);
		border-radius: 19px;
		backdrop-filter: blur(10px);
	}

	.fromPicTitle {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		margin-bottom: 28px;
	}

	.fromPicTitle span {
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9B9F9F;
		line-height: 40px;
		margin-left: 23px;
	}

	.fromPicList {
		display: flex;
		align-items: center;
	}

	.fromPicList img {
		width: 148px;
		height: 148px;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.detimg {
		position: absolute;
		top: 0;
		right: 0;
		width: 30px !important;
		height: 30px !important;
	}

	.submit {
		width: 680px;
		height: 76px;
		background: linear-gradient(90deg, #478CF3 0%, #8ABAF4 100%);
		border-radius: 39px;
		margin: 45px auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 40px;
	}
</style>