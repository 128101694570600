<template>
	<div>
		<!-- 标题 -->
		<div class="title">智慧北站</div>

		<!-- 轮播 -->
		<div class="banner">

			<div class="banner-item">
				<img v-if="banner[banner_index]" :src="banner[banner_index].pic" />
			</div>

			<img class="logo2" src="@/assets/images/logo.png" />
		</div>

		<!--  -->
		<div class="navBox">
			<div class="navItem" v-for="(item, index) in navlist" @click="topage(item.url)">
				<img :src="item.pic" />
				<div>{{item.name}}</div>
			</div>
		</div>

		<!-- 快捷导航 -->
		<div class="">
			<div class="newTitle">
				<div class="newTitleTest" style="margin-left: 10px;">快捷导航</div>
			</div>
			<div class="navBox">
				<div class="navItem" v-for="(item, index) in navlist2" @click="topage(item.url)">
					<img :src="item.pic" />
					<div>{{item.name}}</div>
				</div>
			</div>
		</div>

		<!-- 便民服务 -->
		<div class="">
			<div class="newTitle">
				<div class="newTitleTest" style="margin-left: 10px;">便民服务</div>
			</div>
			<div class="navBox">
				<div class="navItem" v-for="(item, index) in navlist3" @click="topage(item.url)">
					<img :src="item.pic" />
					<div>{{item.name}}</div>
				</div>
			</div>
		</div>

		<!-- 新闻资讯 -->
		<div class="newBox">
			<div class="newTitle">
				<div class="newTitleTest">新闻资讯</div>
				<div class="newTitleIcon" @click="topage('/newlist')">
					更多
					<img src="../../assets/images/icon.png" />
				</div>
			</div>
			<div class="newData">
				<div class="newItem" :class="(index+1) == newList.length ? '' : 'newBorder'"
					v-for="(item, index) in newList" @click="topage('/newinfo?id=' + item.id)">
					<img :src="item.pic" />
					<div>
						<div class="newName">{{item.name}}</div>
						<div class="newTime">{{item.create_time}}</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 地图引导 -->
		<div class="addressBox" @click="topage('/mapnav')">
			<div class="addressTitle">地图引导</div>
			<div class="addtessPic">
				<img src="../../assets/images/address.png" />
			</div>
		</div>
		<div style="height: 50px;"></div>

		<!-- foot -->
		<div class="footBox">
			<div class="footItem" v-for="(item, index) in foot" @click="topage(item.url)">
				<img :src="item.pic" />
				<div :style="index == 0 ? '#4389F6' : ''">{{item.name}}</div>
			</div>
		</div>

		<!-- 开屏页 -->
		<div v-if="openshow == true" class="show">
			<img src="../../assets/images/kaiping.png" />
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		bannerList,
		messageList,
		wechatlogin
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				foot: [{
						pic: require('../../assets/images/icon1-y.png'),
						name: '首页',
						url: '/'
					},
					{
						pic: require('../../assets/images/icon2-n.png'),
						name: '快捷导航',
						url: '/daohang'
					}
				],
				banner: [],
				banner_index: 0,
				navlist: [{
						pic: require('../../assets/images/nav1.png'),
						name: '我要进站',
						url: '/jinzhan'
					},
					{
						pic: require('../../assets/images/nav2.png'),
						name: '我要出站',
						url: '/chuzhan'
					},
					{
						pic: require('../../assets/images/nav3.png'),
						name: '党群服务',
						url: '/fuwu'
					},
					{
						pic: require('../../assets/images/nav4.png'),
						name: '出行查询',
						url: '/chuxing'
					},

				],

				navlist2: [{
						pic: require('../../assets/images/daohang1.png'),
						name: '卫生间',
						url: '/sousuo?name=卫生间&type=3'
					}, {
						pic: require('../../assets/images/daohang4.png'),
						name: '售票中心',
						url: '/sousuo?name=售票中心&type=6'
					},
					{
						pic: require('../../assets/images/daohang5.png'),
						name: '地下停车场一层',
						url: '/sousuo?name=地下停车场一层&type=7'
					},
					{
						pic: require('../../assets/images/daohang6.png'),
						name: '地下停车场二层',
						url: '/sousuo?name=地下停车场二层&type=8'
					},
					{
						pic: require('../../assets/images/daohang7.png'),
						name: '出站闸机',
						url: '/sousuo?name=出站闸机&type=2'
					},
					{
						pic: require('../../assets/images/chuxing1.png'),
						name: '公交站',
						url: '/sousuo?name=公交站'
					},
				],

				navlist3: [{
						pic: require('../../assets/images/peiban.png'),
						name: '陪伴服务',
						url: '/shiwuzhaoling?type=3'
					},
					{
						pic: require('../../assets/images/wupin.png'),
						name: '物品借用',
						url: '/shiwuzhaoling?type=2'
					},
					{
						pic: require('../../assets/images/shiwuzhaoling.png'),
						name: '失物招领',
						url: '/shiwuzhaoling?type=1'
					},
					{
						pic: require('../../assets/images/xingli.png'),
						name: '行李托运',
						url: '/shiwuzhaoling?type=6'
					},
					{
						pic: require('../../assets/images/lunyi.png'),
						name: '特殊人群帮扶',
						url: '/teshulist'
					},
				],

				newList: [],

				code: '',
				wxCode: '',
				openshow: true
			}
		},

		mounted() {
			if (!localStorage.getItem('open')) {
				let timer = setInterval(() => {
					this.openshow = false
					localStorage.setItem('open', 1)
					clearInterval(timer);
				}, 3000)
			} else {
				this.openshow = false
			}
			// var urlStr = window.location.href;
			// this.getCode()

			bannerList().then((res) => {
				this.banner = res.data.data
				this.timer = setInterval(() => {
					if ((this.banner_index + 1) < this.banner.length) {
						this.banner_index = Number(this.banner_index) + 1
					} else {
						this.banner_index = 0
					}
				}, 3000);
			});
			this.getmessageList()
		},

		methods: {
			// getCode() {
			// 	const code = this.getUrlParam("code"); // 截取路径中的code
			// 	if (code == null || code === "") {
			// 		var url = window.location.href;
			// 		window.location.href =
			// 			"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb8dfabc0bf403bb7&redirect_uri=" +
			// 			encodeURIComponent(url) +
			// 			"&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
			// 	} else {
			// 		if (code != "" && code != null) {
			// 			this.wxCode = code;
			// 			console.log(code)
			// 			wechatlogin({
			// 				code: this.wxCode
			// 			}).then((res)=>{
			// 				console.log(res)
			// 			})
			// 		}
			// 	}
			// },

			// 编码函数
			getUrlParam(name) {
				var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
				var r = window.location.search.substr(1).match(reg); //匹配目标参数

				if (r != null) return unescape(r[2]);
				return null; //返回参数值
			},

			getmessageList() {
				messageList({
					page: '1',
					limit: '2',
					cate: 1 //1新闻资讯2党群动态
				}).then((res) => {
					this.newList = res.data.data
				})
			},

			topage(url) {
				if (url == '/') {
					return
				}
				router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped>
	.banner {
		position: relative;
	}

	.banner-item {
		width: 702px;
		height: 350px;
		margin: 35px 24px;
		margin-top: 100px;
	}

	.banner-item img {
		width: 100%;
		height: 100%;
		border-radius: 20px;
	}

	.logo2 {
		position: absolute;
		top: 10px;
		left: 40px;
		width: 126px;
		height: 54px;
	}

	.navBox {
		width: 702px;
		/* height: 233px; */
		padding-top: 20px;
		background: #FFFFFF;
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 20px;
		backdrop-filter: blur(10px);
		margin: 35px 24px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		/* overflow-x: scroll; */
		/* justify-content: space-between; */
	}

	.navItem {
		height: 160px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 26px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 37px;
		width: 25%;
		margin-bottom: 20px;
		text-align: center;
	}

	.navItem img {
		width: 100px;
		height: 100px;
		margin-bottom: 18px;
	}

	.newBox {
		margin: 35px 24px;
	}

	.newTitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.newTitleTest {
		font-size: 34px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 48px;
	}

	.newTitleIcon {
		display: flex;
		align-items: center;
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9EA3A7;
		line-height: 33px;
	}

	.newTitleIcon img {
		width: 11px;
		height: 19px;
		margin-left: 8px;
	}

	.newData {
		width: 702px;
		background: #FFFFFF;
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 22px;
		backdrop-filter: blur(10px);
		margin: 35px 0;
	}

	.newItem {
		display: flex;
		align-items: center;
		padding: 30px 0px;
		margin: 0 25px;
	}

	.newItem img {
		width: 150px;
		height: 150px;
		margin-right: 20px;
	}

	.newName {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		margin-bottom: 24px;
	}

	.newTime {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9EA3A7;
		line-height: 33px;
	}

	.newBorder {
		border-bottom: 1px dashed #D4D4D4;
	}

	.addressBox {
		margin: 35px 24px;
	}

	.addressTitle {
		font-size: 34px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 48px;
		margin-bottom: 29px;
	}

	.addtessPic {
		width: 702px;
		height: 421px;
		background: #FFFFFF;
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 22px;
		backdrop-filter: blur(10px);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.addtessPic img {
		width: 621px;
		height: 281px;
	}

	.show {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1500;
	}

	.show img {
		width: 100%;
		height: 100%;
	}
</style>