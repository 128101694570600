<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {
		name: 'app',
		components: {},
		data() {
			return {
				menuList: []
			}
		},
		created() {

		},
		mounted() {
			this.menuList = this.$router.options.routes
		},
		methods: {
			jump(url) {
				if (url != window.location.hash.slice(1)) {
					this.$router.push({
						path: url
					})
				}
			}
		}
	}
</script>
<style lang="less">
	html,
	body {
		background: linear-gradient(221deg, #D2DAFF 0%, #D9F5FF 100%, #D9F5FF 100%);
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #2c3e50;
		width: 100%;
		// height: 100%;
	}

	.app-menu {
		z-index: 9999;
		position: absolute;
		left: 0;
		top: 30%;
		color: red;

		div {
			cursor: pointer;
		}
	}

	.title {
		width: 100%;
		height: 78px;
		background: #478CF3;
		font-size: 36px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0px;
		z-index: 1200;
	}
	
	.title img{
		position: absolute;
		z-index: 1300;
		top: 50%;
		left: 24px;
		transform: translate(0%, -50%);
		width: 16px;
		height: 29px;
	}
	
	// 导航
	.footBox {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 100%;
		height: 100px;
		background: rgba(254, 255, 255, 0.72);
		backdrop-filter: blur(10px);
		position: fixed;
		bottom: 0;
	}
	
	.footItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 22px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #93BCF9;
		line-height: 30px;
	}
	
	.footItem img {
		width: 38px;
		height: 38px;
		margin-top: 4px;
	}
	
	.logo{
		position: absolute;
		bottom: 100px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 122px;
		height: 53px;
	}
	
	.datanull{
		text-align: center;
		margin-top: 200px;
		font-size: 20px;
		color: #999;
	}
	
	.moreTest{
		text-align: center;
		margin: 10px auto;
		font-size: 20px;
		color: #999;
	}
</style>