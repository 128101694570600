<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />党群服务
		</div>

		<!-- 列表 -->
		<div style="padding-top: 60px;">
			<div class="navBox">
				<div class="navItem" :class="nav_index == index ? 'navItem-y' : ''" v-for="(item, index) in nav"
					@click="switchNav(index)">
					{{item}}
				</div>
			</div>

			<div v-if="nav_index == 0">
				<div class="listItem" v-for="(item , index) in list" @click="topage(item.url)">
					<div>{{item.name}}</div>
					<img src="../../assets/images/icon1.png" />
				</div>
			</div>

			<div v-if="nav_index == 1">
				<div v-if="dqlist.length > 0">
					<div class="newItem" v-for="(item, index) in dqlist" @click="topage('/newinfo?id='+item.id)">
						<img :src="item.pic" />
						<div>
							<div class="newName">{{item.name}}</div>
							<div class="newTime">{{item.create_time}}</div>
						</div>
					</div>
				</div>
				<div v-if="more == 1 && dqlist.length > 0" class="moreTest" @click="nextPage">继续加载</div>
				<div v-if="more == 0 && dqlist.length > 0" class="moreTest">已结到底了</div>
				<div v-if="dqlist.length == 0" class="datanull">暂无动态</div>
			</div>
		</div>

		<!-- logo -->
		<img class="logo" src="../../assets/images/logo.png" />
	</div>
</template>

<script>
	import router from '@/router'

	import {
		messageList
	} from "../../../api/index.js"

	export default {
		data() {
			return {
				nav: ['便民服务', '党群动态'],
				nav_index: 0,
				// 1失物招领2物品借用3陪伴服务4盲人引导5轮椅服务
				list: [{
						name: '失物招领',
						url: '/shiwuzhaoling?type=1'
					},
					{
						name: '物品借用',
						url: '/shiwuzhaoling?type=2'
					},
					{
						name: '陪伴服务',
						url: '/shiwuzhaoling?type=3'
					},
					{
						name: '行李托运',
						url: '/shiwuzhaoling?type=6'
					},
					{
						name: '特殊人群帮扶',
						url: '/teshulist'
					},
					// {
					// 	name: '盲人引导',
					// 	url: '/shiwuzhaoling?type=4'
					// },
					// {
					// 	name: '轮椅服务',
					// 	url: '/shiwuzhaoling?type=5'
					// },
					{
						name: '建议',
						url: '/jianyi'
					},
					{
						name: '投诉',
						url: '/tousu'
					}
				],
				dqlist: [{
					pic: require('../../assets/images/newpic.png'),
					title: '潍坊新增高铁站！最新进展',
					time: '2020年03月18日 06:36'
				}, {
					pic: require('../../assets/images/newpic.png'),
					title: '这一高铁将在日照两地设站',
					time: '2020年03月18日 06:36'
				}],
				
				more: 1,
				page: 1
			}
		},
		
		mounted() {
			this.getmessageList()
		},

		methods: {
			back() {
				router.back()
			},

			switchNav(index) {
				this.nav_index = index
			},

			getmessageList() {
				this.dqlist = this.page == 1 ? [] : this.dqlist
				messageList({
					page: this.page,
					limit: 10,
					cate: '2'
				}).then((res) => {
					if (res.data.data.length > 0) {
						this.more = 1
						for (let i in res.data.data) {
							this.dqlist.push(res.data.data[i])
						}
					} else {
						this.more = 0
					}
				})
			},
			
			nextPage() {
				this.page = this.page + 1
				this.getmessageList()
			},

			topage(url) {
				if (url == '') {
					return
				}
				router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped>
	.navBox {
		display: flex;
		align-items: flex-start;
	}

	.navItem {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		margin: 0 24px;
	}

	.navItem-y {
		font-size: 30px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2E3031;
		line-height: 42px;
		border-bottom: 6px solid #478CF3;
	}

	.listItem {
		display: flex;
		align-items: center;
		width: 652px;
		height: 96px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 19px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		justify-content: space-between;
		margin: 24px;
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		padding: 0 25px;
	}

	.listItem img {
		width: 13px;
		height: 24px;
	}

	.newItem {
		display: flex;
		align-items: center;
		width: 650px;
		padding: 30px 25px;
		margin: 24px 25px;
		background: #FFFFFF;
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 22px;
		backdrop-filter: blur(10px);
	}

	.newItem img {
		width: 150px;
		height: 150px;
		margin-right: 20px;
	}

	.newName {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		margin-bottom: 24px;
	}

	.newTime {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9EA3A7;
		line-height: 33px;
	}
</style>