<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />实时公交
		</div>

		<!-- 列表 -->
		<div style="padding-top: 40px;">
			<div class="listItem" v-for="(item, index) in list" @click="topage('/gongjiaoinfo?id=' + item.id)">
				<img src="../../assets/images/zhanpai.png" />
				<div style="width: 100%;">
					<div class="listLu">
						<div>{{item.name}}</div>
						<div>{{item.child.length}}站</div>
					</div>
					<div class="listZhan">
						<!-- <img src="../../assets/images/gongjiao.png" /> -->
						开往 {{item.child[item.child.length-1].name}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		busList
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				list: []
			}
		},

		mounted() {
			this.getlist()
		},

		methods: {
			back() {
				router.back()
			},

			getlist() {
				busList({}).then((res) => {
					this.list = res.data.data
				})
			},

			topage(url) {
				router.push({
					path: url
				})
			},

			// 富文本处理
			showHtml(str) {
				return str
					.replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
					.replace(/&lt;/g, "<")
					.replace(/&gt;/g, ">")
					.replace(/&quot;/g, "\"")
					.replace(/&#39;/g, "\'")
					.replace(/&amp;nbsp;/g, '\u3000')
					.replace(/&amp;rdquo;/g, "\“")
					.replace(/&amp;ldquo;/g, "\”")
			}
		}
	}
</script>

<style scoped>
	.listItem {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		border-bottom: 1px solid #999;
	}

	.listItem img {
		width: 40px;
		margin-right: 10px;
	}

	.listLu {
		font-size: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.listZhan {
		font-size: 20px;
		margin-top: 10px;
	}

	.listZhan img {
		width: 20px;
		height: 20px;
	}
</style>