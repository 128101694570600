<template>
	<div ref="container" style="height: 100%;">
		<div class="start">
			<div class="start-item" @click="topage('/')">
				<img style="width: 25px;height: 25px;margin-right: 10px;" src="../assets/images/icon1-y.png" />回到首页
			</div>
			<div style="border-left: 1px solid #999;height: 20px;"></div>
			<div class="start-item" @click="start_nav">
				<img style="width: 25px;height: 25px;margin-right: 10px;"
					src="../assets/images/icon2-y.png" />{{startnav_text}}
			</div>
		</div>

		<!-- 上行提示 -->
		<div class="upsty" v-if="upshow">
			<div>{{upTest}}</div>
			<div v-for="(item, index) in upImg">
				<img :src="item" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		onBeforeUnmount
	} from 'vue';
	import {
		Map,
		SpriteMarkerNode,
		thr
	} from 'ooomap';
	import {
		SimulateNavi
	} from 'ooomap-simulate-navi';

	import router from '@/router'

	var upshow = ref(false)
	var upImg = ref([])
	var upTest = ref('')

	const container = ref(null)
	var map
	var floorsComp

	// 路线规划
	var pickCount = 0;
	var building;
	var from, to, simulator, routeResult;
	var to_x, from_x;

	var startMarker, endMarker;

	window.glo = window.glo || {}

	onMounted(() => {

		// 加载地图模型
		map = new Map({
			container: document.body,
			appID: '752e1a4fb13114b9ffb3d801ac73cae5',
			verifyUrl: 'https://www.ooomap.com/ooomap-verify/check/af19109faa1fca6ee94d35105fd3504e',
			backgroundColor: '#ffffff',
			backgroundAlpha: 1,
			viewFloors: [1, 2, 3, 4, 5],
			focusFloors: [1, 2, 3, 4, 5],
			frameRate: 30,
			floorSpace: 700, // 楼层间距, 默认为 68 米
			enableFloorAlpha: false, // false 取消非聚焦楼层的半透明显示
			eventsOnContainer: false, // 事件直接绑定在 canvas 上
			zoomLevel: 15
		})

		map.on('sceneLoaded', scene => {
			map.view.backgroundColor = 0xffffff;
			map.brightness = 1
			map.view.viewFloors = [1, 2, 3, 4, 5]
			map.view.focusFloors = [1, 2, 3, 4, 5]
		})

		// 拾取地图中的对象
		map.on('picked', result => {
			var node = result.node;

			// 只针对 POI 对象
			if (!node || node.type !== 'SpriteMarkerNode') {
				if (building) {
					building.clearRoutes();
					startMarker.show = endMarker.show = false;
				}
				return;
			}
			node.flash();

			console.log(node.position.clone(), result)

			// 先得到此结点的所有父级, 知道他是属于哪个楼层, 哪个建筑
			// ancestor: {buildingID: string, floorNumber: int, building: OMBuilding, floor: OMFloor}
			var ancestor = node.getAncestor();

			// 拾取点计数
			pickCount++;

			// 当拾取2个点后, 创建路径
			if (pickCount % 2 === 0) {
				pickCount = 0;
				routeResult_index = 0;
				car_len = 0;

				// 到达点的数据
				to = {
					floorNumber: ancestor.floorNumber,
					point: node.position.clone()
				}
				to_x = result.intersect.point

				endMarker.position = node.position;
				ancestor.floor.markerNode.add(endMarker);
				endMarker.show = true;

				//
				// 得到两点间的最优路线
				//
				var tujing = {
					floorNumber: 3,
					point: [96.298, 719.747]
				}

				var tujing2 = {
					floorNumber: 3,
					point: [174.742, 371.125]
				}

				if ((from.floorNumber <= 2 && to.floorNumber == 4) || (from.floorNumber == 4 && to
						.floorNumber <= 2)) {
					building.findPath(from, tujing, to).then(res => {
						console.log('routeResult: ', res)
						routeResult = [res[0][0], res[0][1], res[1][1], res[1][2]]
						glo.routeResult = [res[0][0], res[0][1], res[1][1], res[1][2]]
					})
				} else if ((from.floorNumber == 2 && to.floorNumber == 1) || (from.floorNumber == 1 && to
						.floorNumber == 2)) {
					building.findPath(from, tujing2, to).then(res => {
						console.log('routeResult: ', res)
						routeResult = [res[0][0], res[0][1], res[1][1], res[1][2]]
						glo.routeResult = [res[0][0], res[0][1], res[1][1], res[1][2]]
					})
				} else {
					building.findPath(from, to).then(res => {
						console.log('routeResult: ', res)
						routeResult = res
						glo.routeResult = res
					})
				}

			} else {
				// 先清空之前的路径线
				building.clearRoutes();

				// 起始点数据, 生成的路径线较楼板的高度为 1米
				from = {
					floorNumber: ancestor.floorNumber,
					point: node.position.clone()
				}
				from_x = result.intersect.point

				startMarker.position = node.position;
				ancestor.floor.markerNode.add(startMarker);
				startMarker.show = true;
				endMarker.show = false;
			}

		})


		// 标注对象创建完成事件，每一个标注对象创建完成，都会触发次事件
		map.on('markerCreated', marker => {})

		// 加入楼层组件
		floorsComp = new Comp_floors({
			target: document.body,
			props: {
				hasOutdoor: true,
				style: 'right: 10px;bottom: 80px;',
			}
		})
		floorsComp.hasOutdoor = false
		floorsComp.bind(map);

		// 创建POI标记的方法
		function createMarker(url) {
			var marker = new SpriteMarkerNode(map, {
				type: 'poi',
				autoHide: false, // 在遮挡时, 不会自动避让
				priority: 10,
				renderOrder: 3,
				content: {
					image: {
						url: url,
						width: 60,
						height: 60
					}
				}
			})
			// 先将标注隐藏, 在点击起/终点时再显示出来
			marker.show = false
			map.omScene.markerNode.add(marker)
			return marker;
		}

		map.on('buildingLoaded', b => {
			building = b

			startMarker = createMarker('https://www.ooomap.com/assets/map_images/start2.png');
			endMarker = createMarker('https://www.ooomap.com/assets/map_images/end2.png');

			simulator = new SimulateNavi(map, {
				building, // 传入所在的建筑
				startMarker, // 在 ooomap-init 里创建的startMarker
				endMarker // 在 ooomap-init 里创建的endMarker
			})

			// 当模拟导航开始时
			simulator.on('start', description => {
				console.log('>>> 导航描述数据:', description)
			})

			// 当模拟导航结束时
			simulator.on('complete', () => {
				console.log('>>> 模拟导航结束')
			})

			// 当模拟导航结束时, (导航完成也会触发结束事件)
			simulator.on('stop', () => {
				console.log('开启模拟导航')
			})

		})


		// 模型加载完成事件
		map.on('modelCreated', model => {
			switch (model.name) {
				case 'car': // 得到卡车模型对象
					glo.car = model
					break;
				case 'renwu': // 得到人物模型对象
					glo.woman = model
					break;
			}
		})

		map.on("update", start_dh)
	})

	onBeforeUnmount(() => {
		if (floorsComp.floorMaxHeight != 0) {
			map.dispose();
			floorsComp.floorMaxHeight = 0
			floorsComp.has3DButton = false
			floorsComp.hasLayerButton = false
			floorsComp.hasOutdoor = false
		}
	})

	onBeforeUnmount(() => {
		if (floorsComp.floorMaxHeight != 0) {
			map.dispose();
			localStorage.removeItem('id')
			localStorage.removeItem('from')
			localStorage.removeItem('to')
			localStorage.removeItem('louceng')
			localStorage.removeItem('voice')
			floorsComp.floorMaxHeight = 0
			floorsComp.has3DButton = false
			floorsComp.hasLayerButton = false
			floorsComp.hasOutdoor = false
		}
	})

	// 设一个累加的长度
	var car_len = 0
	// 移动的速度
	var speed = 100

	var startnav = false
	let startnav_text = ref('开始导航')

	var routeResult_index = 0

	function start_dh(delta) {
		if (!startnav) {
			return
		}
		// 如果卡车的模型还没有加载进来, 或路线规划没有完成时
		if (!glo.woman || !glo.routeResult) {
			return
		}

		var lineNode = ''
		if (glo.routeResult.length > 1) {
			lineNode = glo.routeResult[routeResult_index].lines[0]
		} else {
			lineNode = glo.routeResult.lines[0]
		}

		// 距离累加
		car_len += delta * speed

		if (car_len > lineNode.length) {
			if (glo.routeResult.length > 1) {
				if ((Number(routeResult_index + 2)) == glo.routeResult.length) {
					car_len = lineNode.length
					startnav_text.value = '重新导航'
					startnav = false
					// car_len = 0
					// window.glo = window.glo || {}
					// routeResult_index = 0
				} else {
					// car_len = 0
					// routeResult_index = routeResult_index + 1

					map.view.zoomLevel = 15
					startnav = false
					// map.view.zoomLevel = 15
					// startnav = false
					var north = new thr.Vector3(0, 1, 0)
					if (glo.routeResult[routeResult_index].floorNumber == 1 && glo.routeResult[routeResult_index + 1]
						.floorNumber == 3) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/1-1.jpg',
							'https://wfbz.boyaokj.cn/bz/2-2.jpg'
						]
						upTest.value = '您正从地下二层停车场前往出站广场层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 3 && glo.routeResult[routeResult_index +
							1].floorNumber == 1) {
						console.log(glo.routeResult[routeResult_index].floorNumber)
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/1-2.jpg']
						upTest.value = '您正从出站广场层前往地下二层停车场...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 3 && glo.routeResult[routeResult_index +
							1].floorNumber == 4) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/3-1.jpg']
						upTest.value = '您正从出站广场层前往进站层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 4 && glo.routeResult[routeResult_index +
							1].floorNumber == 3) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/3-2.png']
						upTest.value = '您正从进站层前往出站广场层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 2 && glo.routeResult[routeResult_index +
							1]
						.floorNumber == 3) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/4-1.png',
							'https://wfbz.boyaokj.cn/bz/5-1.png',
							'https://wfbz.boyaokj.cn/bz/6-1.png'
						]
						upTest.value = '您正从地下一层停车场层前往出站广场层...'
					} else if (glo.routeResult[routeResult_index].floorNumber == 3 && glo.routeResult[routeResult_index +
							1]
						.floorNumber == 2) {
						upshow.value = true
						upImg.value = ['https://wfbz.boyaokj.cn/bz/6-2.png',
							'https://wfbz.boyaokj.cn/bz/5-2.png',
							'https://wfbz.boyaokj.cn/bz/4-2.png'
						]
						upTest.value = '您正从出站广场层前往地下一层停车场层...'
					}
					setTimeout(function() {
						car_len = 0
						routeResult_index = routeResult_index + 1
						map.view.zoomLevel = 16
						startnav = true
						upshow.value = false
					}, 10000)
					// setTimeout(function() {
					// 	car_len = 0
					// 	routeResult_index = routeResult_index + 1
					// 	map.view.zoomLevel = 16
					// 	startnav = true
					// }, 500)
				}
			} else {
				car_len = lineNode.length
				startnav_text.value = '重新导航'
				startnav = false
				// car_len = 0
				window.glo = window.glo || {}
			}
		}

		// 得到线上某一个给定长度的坐标以及方向
		// res = {point, direction}
		var res = lineNode.getPointByLength(car_len)

		// 将车的方向与此位置的方向保持一至
		glo.woman.entity.lookAt(res.point.clone().add(res.direction))
		glo.woman.entity.rotateX(-Math.PI / 2)
		glo.woman.entity.rotateZ(Math.PI / 2)

		// 将车移动到这个位置上
		if (to != '') {
			if (routeResult_index == 0) {
				res.point.z = from_x.z
			} else if (glo.routeResult[routeResult_index].floorNumber == from.floorNumber) {
				res.point.z = from_x.z
			} else if (glo.routeResult[routeResult_index].floorNumber == to.floorNumber) {
				res.point.z = to_x.z
			} else {
				res.point.z = 1433.230909940683
			}
		} else {
			res.point.z = 0
		}

		// if (to != '') {
		// 	if (routeResult_index == 0) {
		// 		res.point.z = from_x.z
		// 	} else {
		// 		res.point.z = to_x.z
		// 	}
		// } else {
		// 	res.point.z = 0
		// }

		glo.woman.position.copy(res.point)

		// 跟随位置
		map.view.viewCenter = glo.woman.position

		var angle = getAngleWithDirection(res.direction)
		// 将此设置为地图的角度
		// map.view.viewAngle = angle
	}

	// 正北方向的向量
	var north = new thr.Vector3(0, 1, 0)

	function getAngleWithDirection(direction) {
		var rad = Math.acos(north.dot(direction))
		var angle = rad * 180 / Math.PI
		return angle
	}

	function start_nav() {
		if (startnav_text.value == '重新导航') {
			car_len = 0
			routeResult_index = 0
		}
		startnav = !startnav
		map.view.zoomLevel = 15
		if (startnav) {
			startnav_text.value = '暂停导航'
		} else {
			startnav_text.value = '继续导航'
		}
	}

	function topage(url) {
		map.dispose();
		localStorage.removeItem('id')
		localStorage.removeItem('from')
		localStorage.removeItem('to')
		localStorage.removeItem('louceng')
		localStorage.removeItem('voice')
		floorsComp.floorMaxHeight = 0
		floorsComp.has3DButton = false
		floorsComp.hasLayerButton = false
		floorsComp.hasOutdoor = false
		router.go(-1)
	}
</script>


<style scoped>
	html,
	body {
		height: 100%;
		margin: 0;
		font-size: 20px;
	}

	#app {
		height: 0px;
		margin: 0;
	}

	.start {
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 95%;
		color: #000;
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 6rpx;
		background-color: #fff;
		box-shadow: 0 8px 12px 0 hsl(0deg 0% 0%);
		border-radius: 5rpx;
		padding: 20px;
		height: 50rpx;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}

	.start-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
	}

	.upsty {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 12000;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	.upsty img {
		margin-top: 10px;
		width: 500px;
	}
</style>