<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />高铁查询
		</div>

		<!-- 列表 -->
		<div style="padding-top: 40px;">
			<div class="tisp">
				如因运力原因或其他不可控因素导致列车调度调整时，当前车型可能会发生变动。
			</div>

			<div class="sousuoBox">
				<div class="sousuoInput">
					<img src="../../assets/images/sousuo.png" />
					<input v-model="keywords" @input="getList" placeholder="请输入关键词搜索" />
				</div>
				<div class="sousuoTest" @click="sousuo">搜索</div>
			</div>

			<div class="gtList">
				<table>
					<header>
						<th>序号</th>
						<th>车次</th>
						<th style="width: 200px;">区间</th>
						<th>到点</th>
						<th>开点</th>
						<th>终到点</th>
					</header>
				</table>
				<tbody class="m-body">
					<tr v-for="(item, index) in list" style="height: 40px;">
						<th>{{index+1}}</th>
						<th>{{item.train_number}}</th>
						<th style="width: 250px;">{{item.interval}}</th>
						<th>{{item.topoint}}</th>
						<th>{{item.openpoint}}</th>
						<th>{{item.endpoint}}</th>
					</tr>
				</tbody>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		Toast
	} from "vant";

	import {
		trainList
	} from "../../../api/index.js"

	export default {
		data() {
			return {
				list: [],
				page: 1,
				keywords: ''
			}
		},

		mounted() {
			this.getList()
		},

		methods: {
			back() {
				router.back()
			},

			getList() {
				xyzList({
					page: this.page,
					limit: 1000,
					keywords: this.keywords
				}).then((res) => {
					this.list = res.data.data
				})
			},

			getList() {
				this.list = this.page == 1 ? [] : this.list
				trainList({
					page: this.page,
					limit: 1000,
					keywords: this.keywords
				}).then((res) => {
					this.list = res.data.data
				})
			},

			sousuo() {

			}
		}
	}
</script>

<style scoped>
	.sousuoBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 656px;
		height: 78px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 48px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 30px 24px;
		padding: 0 23px;
	}

	.sousuoInput {
		display: flex;
		align-items: center;
	}

	.sousuoInput img {
		width: 31px;
		height: 31px;
		margin-right: 24px;
	}

	.sousuoInput input {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		width: 500px;
		border: 0px;
		background-color: #F6F9FF;
	}

	.sousuoTest {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #478CF3;
		line-height: 45px;
	}

	tr:nth-child(odd) {
		/*表示元素中，从1开始 奇数为pink*/
		background-color: #83a9ff88;
	}

	th:nth-child(1) {
		width: 120px;
	}

	th {
		width: 200px;
	}

	.gtList {
		font-size: 22px;
	}

	.tisp {
		text-align: center;
		font-size: 10px;
		padding: 0 10px;
		background: #a4bcff;
		color: #000;
	}
</style>