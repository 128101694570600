<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />智慧北站
		</div>

		<div style="padding-top: 60px;">
			<div class="box" v-if="info">
				<div class="titlebox">{{info.name}}</div>
				<div class="timebox">{{info.create_time}}</div>
				<img class="imgsbox" :src="info.pic" />
				<div class="contbox" v-html="info.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		messageInfo
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				id: '',
				info: ''
			}
		},

		mounted() {
			this.id = this.$route.query.id
			this.getInfo()
		},

		methods: {
			back() {
				router.back()
			},

			getInfo() {
				messageInfo({
					id: this.id
				}).then((res) => {
					this.info = res.data.data
					this.info.content = this.showHtml(res.data.data.content)
				})
			},

			// 富文本处理
			showHtml(str) {
				return str
					.replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
					.replace(/&lt;/g, "<")
					.replace(/&gt;/g, ">")
					.replace(/&quot;/g, "\"")
					.replace(/&#39;/g, "\'")
					.replace(/&amp;nbsp;/g, '\u3000')
					.replace(/&amp;rdquo;/g, "\“")
					.replace(/&amp;ldquo;/g, "\”")
			}
		}
	}
</script>

<style scoped>
	.box {
		margin: 0 25px;
	}

	.titlebox {
		font-size: 42px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 59px;
		margin-bottom: 20px;
	}

	.timebox {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9EA3A7;
		line-height: 33px;
	}

	.imgsbox {
		width: 100%;
		height: 296px;
		margin: 10px 0;
	}

	.contbox {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 50px;
	}
</style>