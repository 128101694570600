<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />实时公交
		</div>

		<!-- 详情 -->
		<div class="Box" style="padding-top: 40px;" v-if="list.length > 0">
			<img src="../../assets/images/jinzhan1.png" style="width: 30px;margin-right: 20px;" />
			<div>
				<div class="listBox" :style="item.type == 1 ? 'color: red' : ''" v-for="(item, index) in list">
					<div class="listIcon" :style="item.type == 1 ? 'background-color: red' : ''"></div>{{index+1}} {{item.name}}
					<div :class="(index+1) != list.length ? 'border' : ''"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		busInfo
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				list: []
			}
		},

		mounted() {
			busInfo({
				id: this.$route.query.id
			}).then((res) => {
				this.list = res.data.data
			})
		},

		methods: {
			back() {
				router.back()
			},
		}
	}
</script>

<style scoped>
	.Box {
		display: flex;
		align-items: center;
		margin: 0 20px;
	}

	.listBox {
		position: relative;
		display: flex;
		align-items: center;
		margin: 40px 20px;
		font-size: 26px;
	}

	.listIcon {
		width: 10px;
		height: 10px;
		background-color: #fff;
		border-radius: 50px;
		margin-right: 20px;
		position: relative;
		z-index: 1200;
	}

	.border {
		width: 10px;
		height: 75px;
		border-left: 10px solid #8fb7ff;
		position: absolute;
		top: 11px;
		left: 2px;
		z-index: 1000;
	}
</style>