<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />智慧北站
		</div>

		<div style="padding-top: 60px;">
			<div v-if="list.length > 0">
				<div class="newItem" v-for="(item, index) in list" @click="topage('/newinfo?id=' + item.id)">
					<img :src="item.pic" />
					<div>
						<div class="newName">{{item.name}}</div>
						<div class="newTime">{{item.create_time}}</div>
					</div>
				</div>
			</div>
			<div v-if="more == 1 && list.length > 0" class="moreTest" @click="nextPage">继续加载</div>
			<div v-if="more == 0 && list.length > 0" class="moreTest">已结到底了</div>
			<div v-if="list.length == 0" class="datanull">暂无新闻</div>
		</div>

		<!-- logo -->
		<img class="logo" src="../../assets/images/logo.png" />
	</div>
</template>

<script>
	import router from '@/router'

	import {
		messageList
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				list: [],
				page: 1,
				more: 1
			}
		},

		mounted() {
			this.getmessageList()
		},

		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},

		methods: {
			back() {
				router.back()
			},

			getmessageList() {
				this.list = this.page == 1 ? [] : this.list
				messageList({
					page: this.page,
					limit: 10,
					cate: 1 //1新闻资讯2党群动态
				}).then((res) => {
					if (res.data.data.length > 0) {
						this.more = 1
						for (let i in res.data.data) {
							this.list.push(res.data.data[i])
						}
					} else {
						this.more = 0
					}
				})
			},

			nextPage() {
				this.page = this.page + 1
				this.getmessageList()
			},

			topage(url) {
				router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped>
	.newItem {
		display: flex;
		align-items: center;
		width: 650px;
		padding: 30px 25px;
		margin: 0 25px;
		background: #FFFFFF;
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 22px;
		backdrop-filter: blur(10px);
		margin-bottom: 24px;
	}

	.newItem img {
		width: 150px;
		height: 150px;
		margin-right: 20px;
	}

	.newName {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		margin-bottom: 24px;
	}

	.newTime {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9EA3A7;
		line-height: 33px;
	}
</style>