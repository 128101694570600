<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />特殊人群帮扶
		</div>

		<!-- 列表 -->
		<div style="padding-top: 40px;">
			<div>
				<div class="listItem" v-for="(item , index) in list" @click="topage(item.url)">
					<div>{{item.name}}</div>
					<img src="../../assets/images/icon1.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		messageList
	} from "../../../api/index.js"

	export default {
		data() {
			return {
				list: [{
						name: '盲人引导',
						url: '/shiwuzhaoling?type=4'
					},
					{
						name: '轮椅服务',
						url: '/shiwuzhaoling?type=5'
					},
				]

			}
		},

		mounted() {

		},

		methods: {
			back() {
				router.back()
			},

			topage(url) {
				if (url == '') {
					return
				}
				router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped>
	.navBox {
		display: flex;
		align-items: flex-start;
	}

	.navItem {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		margin: 0 24px;
	}

	.navItem-y {
		font-size: 30px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2E3031;
		line-height: 42px;
		border-bottom: 6px solid #478CF3;
	}

	.listItem {
		display: flex;
		align-items: center;
		width: 652px;
		height: 96px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 19px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		justify-content: space-between;
		margin: 24px;
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
		padding: 0 25px;
	}

	.listItem img {
		width: 13px;
		height: 24px;
	}

	.newItem {
		display: flex;
		align-items: center;
		width: 650px;
		padding: 30px 25px;
		margin: 24px 25px;
		background: #FFFFFF;
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 22px;
		backdrop-filter: blur(10px);
	}

	.newItem img {
		width: 150px;
		height: 150px;
		margin-right: 20px;
	}

	.newName {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		margin-bottom: 24px;
	}

	.newTime {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9EA3A7;
		line-height: 33px;
	}
</style>