<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			快捷导航
		</div>

		<!-- 搜索 -->
		<div style="padding-top: 30px;">
			<div class="sousuoBox">
				<div class="sousuoInput">
					<img src="../../assets/images/sousuo.png" />
					<input v-model="keywords" placeholder="请输入搜索地点或地址" />
				</div>
				<div class="sousuoTest" @click="sousuo">搜索</div>
			</div>

			<div class="listBox">
				<div class="itemBox" v-for="(item, index) in list" @click="toPage(item.url)">
					<img :src="item.pic" />
					<div>{{item.name}}</div>
				</div>
			</div>
		</div>

		<!-- foot -->
		<div class="footBox">
			<div class="footItem" v-for="(item, index) in foot" @click="toPage(item.url)">
				<img :src="item.pic" />
				<div :style="index == 1 ? '#4389F6' : ''">{{item.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '@/router'

	import {
		Toast
	} from "vant";

	export default {
		data() {
			return {
				foot: [{
						pic: require('../../assets/images/icon1-n.png'),
						name: '首页',
						url: '/'
					},
					{
						pic: require('../../assets/images/icon2-y.png'),
						name: '快捷导航',
						url: '/daohang'
					}
				],
				list: [{
						pic: require('../../assets/images/daohang1.png'),
						name: '卫生间',
						url: '/sousuo?name=卫生间&type=3'
					},
					{
						pic: require('../../assets/images/daohang2.png'),
						name: '进站口',
						url: '/sousuo?name=进站口&type=1'
					},
					{
						pic: require('../../assets/images/daohang3.png'),
						name: '出站口',
						url: '/sousuo?name=出站口&type=2'
					},
					{
						pic: require('../../assets/images/daohang4.png'),
						name: '售票中心',
						url: '/sousuo?name=售票中心&type=6'
					},
					{
						pic: require('../../assets/images/daohang5.png'),
						name: '地下停车场一层',
						url: '/sousuo?name=地下停车场一层&type=7'
					},
					{
						pic: require('../../assets/images/daohang6.png'),
						name: '地下停车场二层',
						url: '/sousuo?name=地下停车场二层&type=8'
					},
					{
						pic: require('../../assets/images/daohang7.png'),
						name: '出站闸机',
						url: '/sousuo?name=出站闸机&type=2'
					}
				],
				keywords: ''
			}
		},

		methods: {
			toPage(url) {
				if (url == '/daohang') {
					return
				}
				router.push({
					path: url
				})
			},

			sousuo() {
				if (this.keywords == '') {
					this.$message.error('请输入要搜索的内容');
					return
				}
				router.push({
					path: '/sousuo?name=' + this.keywords
				})
			}
		}
	}
</script>

<style scoped>
	.sousuoBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 656px;
		height: 78px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(62, 96, 247, 0.13);
		border-radius: 48px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		margin: 51px 24px;
		padding: 0 23px;
	}

	.sousuoInput {
		display: flex;
		align-items: center;
	}

	.sousuoInput img {
		width: 31px;
		height: 31px;
		margin-right: 24px;
	}

	.sousuoInput input {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #51565D;
		line-height: 45px;
		width: 500px;
		border: 0px;
		background-color: #F6F9FF;
	}

	.sousuoTest {
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #478CF3;
		line-height: 45px;
	}

	.listBox {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.itemBox {
		width: 25%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 26px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 37px;
		white-space: nowrap;
		margin-bottom: 35px;
	}

	.itemBox img {
		width: 88px;
		height: 88px;
		margin-bottom: 15px;
	}
</style>