<template>
	<div>
		<!-- 标题 -->
		<div class="title">
			<img @click="back" src="../../assets/images/back.png" />
			车站出站
		</div>

		<!-- 列表 -->
		<div style="padding-top: 40px;">
			<div v-if="list.length > 0">
				<div class="listItem" v-for="(item, index) in list" @click="topage('/nav', item.id)">
					<div class="listBox">
						<img :src="item.icon" />
						<!-- <img src="../../assets/images/jinzhan1.png" /> -->
						<div>{{item.name}}</div>
					</div>
					<img src="../../assets/images/icon1.png" />
				</div>
			</div>
			<div v-if="list.length == 0" class="datanull">暂无数据</div>
		</div>

		<!-- logo -->
		<img class="logo" src="../../assets/images/logo.png" />
	</div>
</template>

<script>
	import router from '@/router'

	import {
		xyzList,
		xyzInfo
	} from '../../../api/index.js'

	export default {
		data() {
			return {
				list: []
			}
		},
		
		mounted() {
			this.getList()
		},

		methods: {
			back() {
				router.back()
			},

			getList() {
				xyzList({
					page: 1,
					limit: 1000,
					type: 2
				}).then((res) => {
					this.list = res.data.data
				})
			},

			topage(url, id) {
				xyzInfo({
					id: id
				}).then((res) => {
					let loulist = [res.data.data.floor_start, res.data.data.floor_end]
					let louceng = Array.from(new Set(loulist))
					for (var i = 1; i <= 9; i++) {
						if (res.data.data.floor_start < res.data.data.floor_end) {
							if (i > res.data.data.floor_start && i < res.data.data.floor_end) {
								louceng.push(i)
							}
						} else {
							if (i > res.data.data.floor_end && i < res.data.data.floor_start) {
								louceng.push(i)
							}
						}
					}
					
					var from = {
						floorNumber: res.data.data.floor_start,
						point: {
							x: res.data.data.x_start,
							y: res.data.data.y_start,
							z: res.data.data.z_start
						}
					}
					var to = {
						floorNumber: res.data.data.floor_end,
						point: {
							x: res.data.data.x_end,
							y: res.data.data.y_end,
							z: 0
						}
					}
					localStorage.setItem('id', id)
					localStorage.setItem('from', JSON.stringify(from))
					localStorage.setItem('to', JSON.stringify(to))
					localStorage.setItem('louceng', JSON.stringify(louceng.sort()));
					localStorage.setItem('voice', res.data.data.voice)
					router.push({
						path: url
					})
				})
			}
		}
	}
</script>

<style scoped>
	.listBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.listBox img {
		width: 36px !important;
		height: 36px !important;
		margin-right: 19px;
	}

	.listItem {
		display: flex;
		align-items: center;
		width: 652px;
		height: 96px;
		background: rgba(255, 255, 255, 0.78);
		box-shadow: 0px 0px 11px 0px rgba(73, 142, 245, 0.2);
		border-radius: 19px;
		border: 2px solid #FFFFFF;
		backdrop-filter: blur(10px);
		justify-content: space-between;
		margin: 24px;
		padding: 0 25px;

		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2E3031;
		line-height: 42px;
	}

	.listItem img {
		width: 13px;
		height: 24px;
	}
</style>